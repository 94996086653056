import React, { useState } from 'react';
import { Button, Dropdown, NavItem } from 'react-bootstrap';
import { BsPersonFill } from 'react-icons/bs';
import { useRouter } from 'next/router';
import Nav from 'react-bootstrap/Nav';
import { useDispatch, useSelector } from 'react-redux';
import { COLOURS } from '../styles/Constants';
import { BasicProfile } from '../interfaces';
import styles from './AccountDropdown.module.css';
import { logoutUser, setShowLoginBox } from '../store/actions/appActions';
import useProviderInfo from '../hooks/useProviderInfo';
import useUserInfo from '../hooks/useUserInfo';
import { isString } from 'lodash';

const style = {
  borderRadius: '0.75em',
  borderColor: COLOURS.red,
  backgroundColor: COLOURS.red,
  color: COLOURS.white,
  marginLeft: '1em',
  marginBottom: 10,
};

interface Props {
  profile: BasicProfile;
}

export const AccountDropdown = (props: Props): JSX.Element => {
  // @ts-ignore
  const { app } = useSelector((state) => state);
  const { userInfo } = app;
  const { isAuthenticated } = app;
  const dispatch = useDispatch();
  const { providerInfo } = useProviderInfo();
  const { handleAuthentication } = useUserInfo();
  const [showMenu, setShowMenu] = useState(false);
  const router = useRouter();
  const { profileName } = providerInfo || {};

  const handleDropdownToggle = (isOpen, event, metadata) => {
    if (isAuthenticated) {
      setShowMenu(!showMenu);
    } else {
      setShowMenu(false);
    }
    handleAuthentication();
  };

  const handleDropdownSelect = (eventKey, event) => {
    event.preventDefault();
    // event.stopPropagation();
    switch (eventKey) {
      case 'dropdown-my-profile-link':
        router.push(`/${providerInfo.profileName}`).catch();
        break;
      case 'dropdown-account-settings':
        router.push('/Settings').catch();
        break;
      case 'dropdown-my-list':
        router.push('/MyList').catch();
        break;
      case 'dropdown-sign-out':
        dispatch(logoutUser());
        router.push('/').catch();
        break;
      default:
        break;
    }
  };

  return (
    <Dropdown
      role="menu"
      navbar
      show={showMenu}
      onToggle={handleDropdownToggle}
      onSelect={handleDropdownSelect}
    >
      <Dropdown.Toggle
        style={style}
        variant="success"
        // onToggle={handleDropdownToggle}
        id="account-dropdown"
      >
        <BsPersonFill size="1.5em" />
      </Dropdown.Toggle>
      {/* {showMenu && ( */}
      <Dropdown.Menu
        // show={showMenu}
        align="right"
        flip
        style={{
          borderRadius: 10,
          width: 180,
          background: COLOURS.lightGrey,
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Dropdown.ItemText>
            {providerInfo?.isEnabled ? (
              // <Dropdown.Item
              //   eventKey="dropdown-my-profile-link"
              //   href={`/${providerInfo.profileName}`}
              //   // href="#"
              //   className={styles.providerProfileName}
              // >
              //   {userInfo.name}
              // </Dropdown.Item>
              // <Button
              //   href={`/${providerInfo.profileName}`}
              //   // eventKey="dropdown-my-profile-link"
              //   className={styles.providerProfileName}
              //   onClick={handleProfileNameClick}
              // >
              //   {userInfo.name}
              // </Button>
              <Nav.Link
                eventKey="dropdown-my-profile-link"
                href={`/${providerInfo.profileName}`}
                className={styles.providerProfileName}
                // onSelect={handleProfileNameClick}
              >
                {userInfo.name}
              </Nav.Link>
            ) : (
              <span className={styles.profileName}>{userInfo.name}</span>
            )}
            {/* <br /> */}
            {
              // <span style={{ fontWeight: 600, fontSize: '0.9em' }}>
              //   {props.profile.title}
              // </span>
              // <br />
            }
          </Dropdown.ItemText>
          {
            // <div style={{ padding: '0.25em 0em' }}>
            //   <Button
            //     style={{
            //       backgroundColor: COLOURS.red,
            //       borderColor: COLOURS.red,
            //     }}
            //     href="/MyList"
            //     onClick={(event) => {
            //       event.preventDefault();
            //       router.push('/MyList');
            //     }}
            //   >
            //   <span style={{ fontWeight: 600, fontSize: '0.9em' }}>
            //     Upcoming Meetings
            //   </span>
            //   </Button>
            // </div>
          }
        </div>
        <Dropdown.Divider
          style={{ borderTop: `1px solid ${COLOURS.highlight}` }}
        />
        <Dropdown.Item
          eventKey="dropdown-account-settings"
          href="/Settings"
          className={styles.dropdownItem}
        >
          <span style={{ fontSize: '0.9em' }}>Account Settings</span>
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="dropdown-my-list"
          href="/MyList"
          className={styles.dropdownItem}
        >
          <span style={{ fontSize: '0.9em' }}>My List</span>
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="dropdown-sign-out"
          href="/"
          className={styles.dropdownItem}
          // onSelect={(eventKey, e) => {
          //   e.preventDefault();
          //   setShowMenu(false);
          //   dispatch(logoutUser());
          //   router.push('/').catch();
          // }}
        >
          <span style={{ fontSize: '0.9em' }}>Sign Out</span>
        </Dropdown.Item>
      </Dropdown.Menu>
      {/* )} */}
    </Dropdown>
  );
};
