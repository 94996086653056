import { useDispatch, useSelector } from 'react-redux';
import urlcat from 'urlcat';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { differenceInMinutes, parseISO } from 'date-fns';
import { updateProviderInfo } from '../store/actions/appActions';
import axios from '../utils/axios';
import logger from '../utils/logger';
import { getCooeeApiUrl } from '../utils/api';
import { getHostEnv } from '../utils/api';

const useProviderInfo = () => {
  // @ts-ignore
  const { app } = useSelector((state) => state);
  const { user, providerInfo } = app;
  const dispatch = useDispatch();
  const baseUrl = getCooeeApiUrl(getHostEnv());

  const refreshProviderInfo = async () => {
    try {
      const { status, data } = await axios.get(
        urlcat(baseUrl, `provider/provider_info`)
      );

      if (status === 200 && !isEmpty(data)) {
        const availability = data.availabilitySelections
          .filter((e) => e.selected)
          .map((e) => e.uuid);
        const newData = { ...data, availability, lastUpdate: new Date() };
        await logger.debug(data);
        dispatch(updateProviderInfo(newData));
      }
    } catch (error) {
      await logger.error(error);
    }
  };

  useEffect(() => {
    if (
      providerInfo &&
      differenceInMinutes(new Date(), parseISO(providerInfo.lastUpdate)) > 5
    ) {
      logger
        .debug(
          'ProviderInfo last update > 5 minutes... Firing refreshProviderInfo...'
        )
        .catch();
      refreshProviderInfo().catch();
    }

    if (!isEmpty(user) && isEmpty(providerInfo)) {
      logger
        .debug('ProviderInfo or User is empty... Firing refreshProviderInfo...')
        .catch();
      refreshProviderInfo().catch();
    }
  }, [user]);

  return {
    refreshProviderInfo,
    providerInfo,
  };
};

export default useProviderInfo;
