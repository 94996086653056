import React, { useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { useRouter } from 'next/router';
import { Dropdown, Form, FormControl } from 'react-bootstrap';
import { useMediaQuery } from '@react-hook/media-query';
import urlcat from 'urlcat';
import { isNil, isArray, isString, startCase, isEmpty, toNumber } from 'lodash';
import { COLOURS } from '../../styles/Constants';
import styles from './SearchBar.module.css';
import axios from '../../utils/axios';
import { fetchDelayInMs } from '../../utils/common';
import logger from '../../utils/logger';
import { getCooeeApiUrl } from '../../utils/api';
import { getHostEnv } from '../../utils/api';
import { getUserFullName } from '../../utils/helpers';
import useUserInfo from '../../hooks/useUserInfo';

interface Props {
  mainColour: string;
}

export const SearchBar = (props: Props): JSX.Element => {
  const router = useRouter();
  const { userInfo } = useUserInfo();
  const baseUrl = getCooeeApiUrl(getHostEnv());
  const [searchQuery, setSearchQuery] = useState('');
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchResultTag, setSearchResultTag] = useState([]);
  const [searchResultChannel, setSearchResultChannel] = useState([]);
  const isNotMobileScreen = useMediaQuery('only screen and (min-width: 600px)');
  const [dropdownSelectionIndex, setDropdownSelectionIndex] = useState(-1);

  const iconStyles = { color: 'white', fontSize: '1.5em' };

  const handleSearchQueryChange = (e: string) => {
    setSearchFocused(true);
    // setSearchQuery(e.replace(/\b(\w)/g, (s) => s.toUpperCase()));
    setSearchQuery(e.replace(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/g, ''));

    // When the search field is emptied, clear all cached result arrays
    if (e.length === 0 || e.length === 1) {
      setSearchResultChannel([]);
      setSearchResultTag([]);
    }
  };

  useEffect(() => {
    // https://joaoforja.com/blog/5-steps-to-perform-a-search-when-user-stops-typing-using-react-+-hooks-in-a-controlled-component/
    const { cancel, token } = axios.CancelToken.source();
    const timeOutId = setTimeout(() => {
      if (isString(searchQuery) && searchQuery.length > 2) {
        (async () => {
          if (searchQuery.length > 0) {
            try {
              const { status, data } = await axios.get(
                urlcat(baseUrl, 'tags', { search: searchQuery })
              );
              if (status === 200 && data.data) {
                if (isArray(data.data.tags) && data.data.tags.length > 0) {
                  setSearchResultTag(
                    data.data.tags.map((e: any) => ({ ...e, type: 'tag' }))
                  );
                }
                if (
                  isArray(data.data.channels) &&
                  data.data.channels.length > 0
                ) {
                  setSearchResultChannel(
                    data.data.channels.map((e: any) => ({
                      ...e,
                      type: 'channel',
                    }))
                  );
                }
              }
              // Only sends when search is in focus
              if (searchFocused)
                logger
                  .info(
                    `${getUserFullName(
                      userInfo
                    )} opened search box with input ${searchQuery} and received result ${JSON.stringify(
                      data.data
                    )}`
                  )
                  .catch();
            } catch (err) {
              logger.error(err).catch();
            }
          }
        })();
      }
    }, fetchDelayInMs);
    // @ts-ignore
    return () => cancel('No longer latest query') || clearTimeout(timeOutId);
  }, [searchQuery]);

  const getDropdownItemSelection = (itemIndex: number) => {
    let list = [];
    list = [{ type: 'keyword', query: searchQuery.trim(), index: 0 }];
    if (isArray(searchResultTag))
      list = [
        ...list,
        ...searchResultTag.map((e, i) => ({
          type: 'tag',
          query: e,
          index: 1 + i,
        })),
      ];
    if (isArray(searchResultChannel))
      list = [
        ...list,
        ...searchResultChannel.map((e, i) => ({
          type: 'channel',
          query: e,
          index: 1 + searchResultTag.length,
        })),
      ];
    return list[itemIndex];
  };

  const handleSearchSelection = (event, type?: string, eventKey?) => {
    setSearchFocused(false);
    let item: any;
    let currentSelectionIndex = dropdownSelectionIndex;

    if (event.key === 'Enter' && isNil(type) && isNil(eventKey)) {
      // Enter pressed
      // const searchTermMatches =
      //   (isArray(searchResultTag) &&
      //     searchResultTag.filter(
      //       (e) => e.name.toLowerCase() === searchQuery.toLowerCase()
      //     ).length > 0) ||
      //   (isArray(searchResultChannel) &&
      //     searchResultChannel.filter(
      //       (e) => e.name.toLowerCase() === searchQuery.toLowerCase()
      //     ).length > 0);
      //
      // if (searchTermMatches) {
      //   currentSelectionIndex = 1;
      // }

      // console.log(searchTermMatches);

      if (currentSelectionIndex > 0) {
        item = getDropdownItemSelection(currentSelectionIndex);
        type = item.type;
      } else {
        item = getDropdownItemSelection(0);
        type = item.type;
      }
      logger
        .info(
          `Selection using keyboard search type ${type} search query ${JSON.stringify(
            item.query
          )}`
        )
        .catch();
    } else if (!isEmpty(event) && isString(type) && isString(eventKey)) {
      // Manual selection
      item = getDropdownItemSelection(toNumber(eventKey));
      type = item.type;
      logger
        .info(
          `Selection using mouse/tap search type ${type} search query ${JSON.stringify(
            item.query
          )}`
        )
        .catch();
      if (type !== item.type) {
        logger.warn('Potentially unmatched search operation!').catch();
      }
    } else {
      logger.error('Unmatched search operation!').catch();
    }

    logger
      .info(
        `${getUserFullName(
          userInfo
        )} confirmed search criteria by ${type} item ${JSON.stringify(item)}`
      )
      .catch();

    switch (type) {
      case 'keyword':
        setSearchQuery(searchQuery.trim());
        router.push(
          `/Search?query=${item.query}&type=keyword&keyword=${item.query}`,
          `/Search?query=${item.query}`
        );
        break;
      default:
        console.log(
          `/Search?query=${item.query.name}&uuid=${item.query.uuid}&nonce=${item.query.nonce}&type=${type}`
        );
        setSearchQuery(item.query.name);
        router.push(
          `/Search?query=${item.query.name}&uuid=${item.query.uuid}&nonce=${item.query.nonce}&type=${type}`,
          `/Search?query=${item.query.name}`
        );
        break;
    }
    // setSearchQuery('');
  };

  // useEffect(() => {
  //   if (
  //     searchQuery.length > 0 &&
  //     !(
  //       (isArray(searchResultKeyword) &&
  //         searchResultKeyword.filter(
  //           (e) => e.name.toLowerCase() === searchQuery.toLowerCase()
  //         ).length > 0) ||
  //       (isArray(searchResultChannel) &&
  //         searchResultChannel.filter(
  //           (e) => e.name.toLowerCase() === searchQuery.toLowerCase()
  //         ).length > 0)
  //     )
  //   ) {
  //     console.log('nothing found');
  //   } else {
  //     console.log('found something');
  //   }
  // }, [enterPressed]);

  const handleItemSelection = (eventKey: any, event: any, type: string) => {
    event.preventDefault();
    setDropdownSelectionIndex(eventKey);
    handleSearchSelection(event, type, eventKey);
  };

  const handleDropdownArrowNavigation = (event) => {
    const totalLength = searchResultTag.length + searchResultChannel.length;
    const maxLength = totalLength + 1;
    let newSelectionIndex = 0;
    switch (event.code) {
      case 'ArrowUp':
        newSelectionIndex =
          dropdownSelectionIndex > 0 ? dropdownSelectionIndex - 1 : 0;
        event.preventDefault();
        break;
      case 'ArrowDown':
        newSelectionIndex =
          dropdownSelectionIndex < maxLength - 1
            ? dropdownSelectionIndex + 1
            : dropdownSelectionIndex;
        event.preventDefault();
        break;
      default:
    }
    console.log(`next selection index: ${newSelectionIndex}`);
    setDropdownSelectionIndex(newSelectionIndex);
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const toggleSearchSuggestion = (enable: boolean) => {
    if (enable) {
      setSearchQuery('');
      setSearchResultTag([]);
      setSearchResultChannel([]);
    }
    if (searchQuery.length === 0 && enable !== searchFocused) {
      setSearchFocused(enable);
    }
  };

  const getHighlightedText = (text, highlight) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text.name.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, idx) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={`${part.toLowerCase()}-${idx}`}>
              <b>{part}</b>
            </span>
          ) : (
            <span key={`${part.toLowerCase()}-${idx}`}>{part}</span>
          )
        )}
      </span>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <Form inline onSubmit={handleSearchSelection}>
        <BsSearch
          size="0.9em"
          style={{
            position: 'absolute',
            display: 'list-item',
            marginLeft: '0.45em',
            color: 'white',
          }}
        />
        <FormControl
          type="text"
          placeholder={isNotMobileScreen ? 'Search Cooee' : 'Search'}
          role="search"
          className={
            isNotMobileScreen ? styles.searchInput : styles.mobileSearchInput
          }
          style={{
            width: '100%',
            borderRadius: '0.8em',
            textIndent: '1.5em',
            border: `0.2em solid ${props.mainColour}`,
            backgroundColor: 'transparent',
            color: props.mainColour,
          }}
          id="inlineFormSearchCooee"
          onFocus={() => toggleSearchSuggestion(true)}
          onBlur={() => toggleSearchSuggestion(false)}
          // value={searchQuery}
          onChange={(e) => handleSearchQueryChange(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleSearchSelection(event);
            } else handleDropdownArrowNavigation(event);
          }}
        />
      </Form>
      {searchFocused ? (
        <>
          <div
            style={{
              position: 'absolute',
              fontSize: '0.6em',
              // paddingTop: '0.25em',
              // paddingBottom: '0.25em',
              width: '100%',
              backgroundColor: COLOURS.lightGrey,
              marginTop: '0.5em',
              minWidth: 200,
              borderRadius: '10px',
              textAlign: 'left',
              // height: '1.8em',
            }}
          >
            {searchQuery === '' ? (
              <Dropdown.ItemText
                style={{ marginBottom: 10, marginTop: 10, paddingLeft: '1em' }}
              >
                <b>Search</b> topics, professionals and skills
              </Dropdown.ItemText>
            ) : (
              <>
                <div style={{ marginTop: 10 }} />
                {searchQuery.length > 0 &&
                  isArray(searchResultTag) &&
                  searchResultTag.length === 0 &&
                  isArray(searchResultChannel) &&
                  searchResultChannel.length === 0 && (
                    // !(
                    //   (isArray(searchResultTag) &&
                    //     searchResultTag.filter(
                    //       (e) =>
                    //         e.name.toLowerCase() === searchQuery.toLowerCase()
                    //     ).length > 0) ||
                    //   (isArray(searchResultChannel) &&
                    //     searchResultChannel.filter(
                    //       (e) =>
                    //         e.name.toLowerCase() === searchQuery.toLowerCase()
                    //     ).length > 0)
                    // ) && (
                    // <Dropdown.Item
                    //   key="keyword-search"
                    //   className={styles.dropdownItemMiddleItem}
                    //   active={dropdownSelectionIndex === 0}
                    //   eventKey={0}
                    //   // active={false}
                    //   href={`/Search?query=${searchQuery}`}
                    //   onSelect={(eventKey, event) => {
                    //     console.log('Search by keyword');
                    //     handleItemSelection(eventKey, event, 'keyword');
                    //     toggleSearchSuggestion(false);
                    //   }}
                    //   // onFocus={toggleSearchSuggestion(true)}
                    // >
                    //   <span>
                    //     Keyword: <b>{startCase(searchQuery)}</b>
                    //   </span>
                    // </Dropdown.Item>
                    <Dropdown.ItemText style={{ paddingLeft: '1em' }}>
                      <b>Search</b> topics, professionals and skills
                    </Dropdown.ItemText>
                  )}
                {searchResultTag.length > 0 && (
                  <>
                    {searchResultTag.map((tag, index) => (
                      <Dropdown.Item
                        key={`tag-${tag.name}`}
                        className={styles.dropdownItemMiddleItem}
                        active={index + 1 === dropdownSelectionIndex}
                        eventKey={`${index + 1}`}
                        href={`/Search?query=${tag.name}`}
                        onSelect={(eventKey, event) => {
                          console.log('Search by tag');
                          handleItemSelection(eventKey, event, 'tag');
                          toggleSearchSuggestion(false);
                        }}
                      >
                        {getHighlightedText(tag, searchQuery)}
                      </Dropdown.Item>
                    ))}
                  </>
                )}
                {searchResultChannel.length > 0 && (
                  <>
                    <div
                      style={{
                        marginLeft: '1.5em',
                        marginTop: '0.5em',
                        marginBottom: '0.5em',
                      }}
                    >
                      <span
                        style={{
                          color: 'grey',
                          fontSize: '0.8em',
                          fontWeight: 'bold',
                        }}
                      >
                        CHANNELS
                      </span>
                    </div>
                    {searchResultChannel.map((channel, index) => (
                      <Dropdown.Item
                        key={`channel-${channel.name}`}
                        className={styles.dropdownItemMiddleItem}
                        href={`/Search?query=${channel.name}`}
                        eventKey={searchResultTag.length + 1 + index}
                        active={
                          searchResultTag.length + index + 1 ===
                          dropdownSelectionIndex
                        }
                        onSelect={(eventKey, event) => {
                          console.log('Search by channel');
                          handleItemSelection(eventKey, event, 'channel');
                          toggleSearchSuggestion(false);
                        }}
                        // active={index === dropdownSelectionIndex}
                        // eventKey={index}
                        // href={`/Search?query=${keyword}`}
                        // onSelect={(eventKey, event) => {
                        //   handleSelection(eventKey, event);
                        //   toggleSearchSuggestion(false);
                        // }}
                        // active={
                        //   index + searchResultKeyword.length ===
                        //   dropdownSelectionIndex
                        // }
                      >
                        {channel.name}
                      </Dropdown.Item>
                    ))}
                  </>
                )}

                <div style={{ marginTop: 10 }} />
                {/* </Dropdown.Menu> */}
              </>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};
