import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@react-hook/media-query';
import { AccountDropdown } from './AccountDropdown';
import { SearchBar } from './SearchBar/SearchBar';
import { COLOURS } from '../styles/Constants';
import { BasicProfile } from '../interfaces';
import { CompanyLogo } from './CompanyLogo';
import { isMobile } from 'react-device-detect';

const dummyData: BasicProfile = {
  id: 7,
  firstName: 'Harry',
  lastName: 'Potter',
  title: 'Quidditch Seeker',
  verified: true,
  profilePictureUrl: 'ss',
  coverPictureUrl: 'ss',
  numConversations: 99,
  conversation: 'testConversation',
};

export const NavigationBar = (): JSX.Element => {
  const router = useRouter();
  const isWideScreen = useMediaQuery('only screen and (min-width: 600px)');
  const isNotTooNarrow = useMediaQuery('only screen and (min-width: 400px)');
  const isCurrentPage = (menu) => router.pathname.includes(menu);
  const navItemStyle = {
    paddingLeft: 'clamp(1%, 1.8em, 2.8em)',
    fontSize: '1.5em',
  };
  const firstNavItemStyle = {
    ...navItemStyle,
    paddingLeft: 'clamp(1%, 1.8em, 2.8em)',
    // paddingLeft: '82px',
  };
  const lastNavItemStyle = {
    ...navItemStyle,
    paddingLeft: 0,
    marginLeft: 'auto',
    paddingTop: 15,
  };

  const textColour = {
    color: COLOURS.white,
  };

  const handleNavigationSelect = (eventKey, event) => {
    switch (eventKey) {
      case 'channel':
        router.push('/Channels');
        break;
      case 'my-list':
        router.push('/MyList');
        break;
      case 'home':
        router.push('/');
        break;
      default:
        break;
    }
  };

  return (
    <Navbar
      style={{
        position: 'absolute',
        zIndex: 10,
        paddingLeft: '5%',
        width: '100%',
      }}
      role="navigation"
      onSelect={handleNavigationSelect}
    >
      <Navbar.Brand href="/">
        <CompanyLogo
          size={isNotTooNarrow ? 100 : 70}
          company="Cooee"
          colourType="colour"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="sr-auto align-items-center">
          <div className="d-none d-xl-block" style={firstNavItemStyle}>
            <Nav.Link
              style={textColour}
              eventKey="home"
              href="/"
              onSelect={(eventKey, e) => {
                e.preventDefault();
              }}
            >
              <span
                style={isCurrentPage('Home') ? { fontWeight: 'bold' } : null}
              >
                Home
              </span>
            </Nav.Link>
          </div>
          <div className="d-none d-xl-block" style={navItemStyle}>
            <Nav.Link
              style={textColour}
              eventKey="channel"
              onSelect={(eventKey, e) => {
                e.preventDefault();
              }}
            >
              <span
                style={
                  isCurrentPage('Channels') ? { fontWeight: 'bold' } : null
                }
              >
                Channels
              </span>
            </Nav.Link>
          </div>
          <div
            // className="d-none d-sm-block"
            style={
              isWideScreen
                ? { ...navItemStyle, paddingTop: '0.2em' }
                : { ...navItemStyle, paddingLeft: 0, paddingTop: '0.2em' }
            }
          >
            <SearchBar mainColour={COLOURS.white} />
          </div>
        </Nav>
        <div style={{ ...lastNavItemStyle }}>
          <AccountDropdown profile={dummyData} />
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};
