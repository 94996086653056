interface Props {
  size?: number;
  width?: number;
  height?: number;
  logo_img: string;
}

// https://dev.to/abachi/how-to-change-svg-s-color-in-react-42g2
export const Logo = (props: Props): JSX.Element => {
  return (
    <img
      src={props.logo_img}
      className="d-inline-block align-top"
      alt="logo"
      width={`${props.width || props.size}`}
      height={`${props.height || props.size}`}
    />
  );
};
