import { Logo } from './Logo';

const fb_black = '/images/svg/fb-icon-black.svg';
const fb_col = '/images/svg/fb-icon-colour.svg';
const linkedin_col = '/images/svg/linkedin-icon-colour.svg';
const twitter_black = '/images/svg/twitter-icon-black.svg';
const instagram_black = '/images/svg/instagram-icon-black.svg';
const cooee_col = '/images/svg/logo.svg';

interface Company {
  width?: number;
  height?: number;
  size?: number;
  colourType: string;
  company: 'LinkedIn' | 'Facebook' | 'Twitter' | 'Cooee' | 'Instagram';
}

type CompanyProps = LinkedIn | Facebook | Twitter | Cooee | Instagram;

interface Instagram extends Company {
  colourType: 'black';
  company: 'Instagram';
}

interface Cooee extends Company {
  colourType: 'colour';
  company: 'Cooee';
}

interface LinkedIn extends Company {
  colourType: 'colour';
  company: 'LinkedIn';
}

interface Facebook extends Company {
  colourType: 'black' | 'colour';
  company: 'Facebook';
}

interface Twitter extends Company {
  colourType: 'black';
  company: 'Twitter';
}

export const CompanyLogo = (props: CompanyProps): JSX.Element => {
  let variant: string;

  if (props.company === 'LinkedIn') {
    variant = linkedin_col;
  } else if (props.company === 'Facebook') {
    if (props.colourType === 'black') variant = fb_black;
    else variant = fb_col;
  } else if (props.company === 'Twitter') {
    variant = twitter_black;
  } else if (props.company === 'Instagram') {
    variant = instagram_black;
  } else {
    variant = cooee_col;
  }

  return (
    <Logo
      size={props.size}
      width={props.width}
      height={props.height}
      logo_img={variant}
    />
  );
};
