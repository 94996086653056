export const COLOURS = {
  red: '#EC1F35',
  tickBlue: '#424EFC',
  white: '#FFFFFF',
  lightGrey: '#FFFFFFCC',
  lighterGrey: '#FFFFFFBF',
  fbBlue: '#407EC0',
  darkGrey: '#1c1c1c',
  evenDarkerGrey: '#101010',
  darkGreyTransparent: '#1C1C1CD9',
  black: '#040404',
  midGrey: '#707070',
  highlight: '#0404041A',
};

export const defaultButtonStyle = {
  width: '100%',
  marginBottom: '0.75em',
  padding: '1em 1em',
  backgroundColor: COLOURS.white,
  borderColor: COLOURS.white,
  color: COLOURS.fbBlue,
  fontSize: '0.8em',
};
